import React from 'react'
import { Switch, Route } from "react-router-dom"
import ChangePasswordPage from './change-password/change-password.page'

const SecurityController = () => {
    return (
        <Switch>
            <Route path="/security/change-password" component={ChangePasswordPage} />
        </Switch>
    )
}

export default SecurityController