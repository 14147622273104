import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Service from './newsletter.service'
import NumberFormat from 'react-number-format'
import './newsletter.styles.scss'

class Newsletter extends React.Component {

    constructor(){
        super()
        this.service = Service(this)
        this.state = this.service.getDefaultState() 
    }

    componentDidMount(){
        this.service.loadMetrics()
    }

    render() {
        return (
            <div className="card newsletter">
                {
                    this.state.loading && <Dialog><Loading /></Dialog>
                }
                <h4>Newsletter</h4>
                <p>Recipients <NumberFormat displayType={'text'} value={this.state.metrics[0]} renderText={value => <strong>{value}</strong>}/></p>
                <p>Blacklisted <NumberFormat displayType={'text'} value={this.state.metrics[1]} renderText={value => <strong>{value}</strong>}/></p>
                <button onClick={this.service.exportRecipients}>Download</button>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Newsletter))