import iconHome from '../../../assets/icons/home.svg'

const DefaultStore = {
    showMenu: false,
    activeItem: 0,
    items: [
        {
            text: "Dashboard",
            icon: iconHome,
            subItems: []
        },
        {
            text: "Article",
            icon: iconHome,
            subItems: [
                {
                    url: "/article/articles",
                    text: "Articles"
                },
                {
                    url: "/article/article-types",
                    text: "Types"
                }
            ]
        },
        {
            text: "Manuscript",
            icon: iconHome,
            subItems: [
                {
                    url: "/manuscripts",
                    text: "Manuscripts"
                }
            ]
        },
        {
            text: "Journal",
            icon: iconHome,
            subItems: [
                {
                    url: "/journal/journals",
                    text: "Journals"
                },
                {
                    url: "/journal/pages",
                    text: "Pages"
                },
                {
                    url: "/journal/groups",
                    text: "Groups"
                }
            ]
        },
        {
            text: "Content",
            icon: iconHome,
            subItems: [
                {
                    url: "/content/pages",
                    text: "Pages"
                }
            ]
        },
        {
            text: "Security",
            icon: iconHome,
            subItems: [
                {
                    url: "/security/change-password",
                    text: "Change Password"
                }
            ]
        }
    ]
}

export default DefaultStore