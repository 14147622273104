import React from 'react'
import './dashboard.styles.scss'
import NewsletterComponent from './newsletter/newsletter.component'

const DashboardPage = () => {
    return (
        <div className="dashboard">
            <div className="cards">
                <NewsletterComponent/>
            </div>
        </div>
    )
}

export default DashboardPage
