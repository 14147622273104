import React from 'react'
import FormHelper from '../../../utils/FormHelper'
import ChangePasswordForm from './form/form.component'
import Service from './change-password'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

class ChangePassword extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.changePassword()
    }

    render() {
        return (
            <div className="user-create">
                <div className="card">
                    <div className="header">
                        <h4>Update Password</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <ChangePasswordForm {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit}/>
                </div>
            </div>
        )
    }
}

export default withRouter(ChangePassword)